import React, { useEffect, useState } from "react";
import "./App.css";
import { Admin, CustomRoutes } from "react-admin";
import StandardLayout from "./components/StandardLayout";
import auth from "./resources/auth";
import helpdesk from "./resources/helpdesk";
import i18nProvider from "./translations/i18n-provider";
import { authProvider } from "./providers/authProvider";
import LoginPage from "./components/login/LoginPage";
import theme from "./themes";
import { dataProvider } from "./providers/dataProvider";
import { setConfig } from "./utils/config";
import { Route } from "react-router-dom";
import ParkingPrepaid from "./resources/parking/prepaid";
import ErrorPage from "./resources/parking/prepaid/ErrorPage";
import BasePage from "./resources/parking/pages/BasePage";
import { ParkingContextProvider } from "./services/parking/parkingContext";
import parking from "./resources/admin-parking";
import useHelpdeskSettings from "./hooks/useHelpdeskSettings";
import useParkingSettings from "./hooks/useParkingSettings";
import { SettingsManager } from "./constants/SettingsManager";
import { QueryClient } from "react-query";
import mobile from "./resources/mobile";
import OrganizationErrorPage from "./resources/parking/prepaid/OrganizationErrorPage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const [init, setInit] = useState<boolean>(false);
  useHelpdeskSettings();
  useParkingSettings();

  const getResources = (permissions: string[]) => {
    const isHelpdeskModuleEnabled =
      SettingsManager.getInstance().getHelpdeskSettings()?.enabled;
    const parkingSettings = SettingsManager.getInstance().getParkingSettings();

    let resources = auth();
    resources = resources.concat(parking(permissions, parkingSettings));
    resources = resources.concat(
      helpdesk(permissions, isHelpdeskModuleEnabled),
    );

    resources = resources.concat(mobile());

    return resources;
  };

  const initConfig = async () => {
    await setConfig();
    setInit(true);
  };

  useEffect(() => {
    initConfig();
  }, []);

  if (!init) {
    return <></>;
  }

  return (
    <ParkingContextProvider>
      <Admin
        theme={theme}
        authProvider={authProvider}
        loginPage={LoginPage}
        dataProvider={dataProvider}
        layout={StandardLayout}
        queryClient={queryClient}
        i18nProvider={i18nProvider}
        disableTelemetry
      >
        {(permissions) => {
          return (
            <>
              {getResources(permissions)}
              <CustomRoutes noLayout>
                <Route
                  path="parking/prepaid/:orgId/steps/:step"
                  element={<ParkingPrepaid />}
                />
                <Route
                  path="parking/prepaid/:orgId/*"
                  element={<ErrorPage />}
                />
                <Route
                  path="parking/prepaid/:orgId/organization_error"
                  element={<OrganizationErrorPage />}
                />
                <Route
                  path="parking/prepaid/:orgId/page/:page"
                  element={<BasePage />}
                />
              </CustomRoutes>
            </>
          );
        }}
      </Admin>
    </ParkingContextProvider>
  );
}

export default App;
